<template>
  <div class="">
    <div class="tab-list">
      <div
        :class="activenum == item.id ? 'activeclass' : ''"
        v-for="(item, index) in tablist"
        :key="index"
        @click="changetab(item.path)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
    <el-card class="box-card">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="正常考勤时间："
          prop="signOutDate"
          :rules="[{ required: true, message: '请选择考勤时间' }]"
        >
          <!-- <el-select v-model="ruleForm.signInDate" placeholder="请选择时间" style="width: 227px;margin-right: 20px;">
						<el-option v-for="item in gzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select> -->
          <el-time-picker
            v-model="ruleForm.signInDate"
            placeholder="请选择时间"
            format="HH:mm"
          >
          </el-time-picker>
          <span style="margin: 0 20px">-</span>
          <!-- <el-select v-model="ruleForm.signOutDate" placeholder="请选择时间" style="width: 227px;">
						<el-option v-for="item in gzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select> -->
          <el-time-picker
            v-model="ruleForm.signOutDate"
            placeholder="请选择时间"
            format="HH:mm"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item
          label="考勤范围："
          prop="lng"
          :rules="[{ required: true, message: '请选择考勤范围' }]"
        >
          <!-- <el-select v-model="ruleForm.fanwei" placeholder="请选择考勤范围" style="width: 227px;margin-right: 20px;">
						<el-option v-for="item in gzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select> -->
          <el-input
            v-model="ruleForm.signAddress"
            placeholder="请选择考勤范围"
            @click.native="workDialogVisible = true"
            style="width: 227px; margin-right: 20px"
          ></el-input>
          <span style="margin-right: 20px">-</span>
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.signRange"
            style="width: 227px"
          >
            <template slot="append">米</template>
          </el-input>
        </el-form-item>
        <el-form-item
            label="加班工资结算："
            prop="extWorkSalary"
            :rules="[{required:true, message: '请选择工资结算方式'}]"
        >
          <el-select
            v-model="ruleForm.extWorkSalary"
            placeholder="请选择结算方式"
            style="width: 500px"
          >
            <el-option label="自定义加班工资" :value="1"></el-option>
            <el-option label="加班时间计算为调休" :value="2"></el-option>
            <el-option label="无加班费" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="extWorkDescription"
          v-if="ruleForm.extWorkSalary == 1 || ruleForm.extWorkSalary == 2"
          :rules="
            ruleForm.extWorkSalary == 1
              ? [{ required: true, message: '请填写加班规则' }]
              : []
          "
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 10 }"
            show-word-limit
            placeholder="请输入公司加班定义规则，平台将根据您的规则进行技术者的工资结算"
            v-model="ruleForm.extWorkDescription"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 工作地点弹窗 -->
    <!-- :close-on-click-modal="false" -->
    <el-dialog
      :visible.sync="workDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="work-box-out"
    >
      <div class="work-box">
        <span class="closeicon" @click="closeworkDialogbox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font20">考勤范围</div>
        <div class="frombox">
          <el-form
            :model="workForm"
            ref="workForm"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="考勤地址："
              prop="street"
              :rules="[{ required: true, message: '请填写考勤地址' }]"
            >
              <el-select
                v-model="tipInput"
                class="tipInput"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入考勤地址"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="tipChange"
              >
                <el-option
                  v-for="item in tips"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.district
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="详细地址："
              prop="housenumber"
              :rules="[{ required: true, message: '请填写详细地址' }]"
            >
              <el-input
                type="text"
                style="width: 300px"
                v-model="workForm.housenumber"
                placeholder="请输入详细门牌号"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <img
              v-if="!markers.length"
              class="amap-wrapper"
              src="../../assets/images/common/map.png"
            />
            <div class="amap-wrapper" v-else>
              <el-amap
                class="amap-box"
                :zoom="zoom"
                :center="center"
                :mapStyle="mapStyle"
              >
                <el-amap-marker
                  v-for="(marker, index) in markers"
                  :position="marker.position"
                  :events="marker.events"
                  :vid="index"
                  :icon="marker.icon"
                  v-bind:key="index"
                >
                </el-amap-marker>
                <el-amap-info-window
                  v-if="window"
                  :position="window.position"
                  :visible="window.visible"
                  :content="window.content"
                  :offset="window.offset"
                  :is-custom="true"
                >
                  <div id="info-window">
                    <p>{{ window.address }}</p>
                  </div>
                </el-amap-info-window>
              </el-amap>
            </div>
            <el-form-item>
              <el-button class="qux" @click="workDialogVisible = false"
                >取消</el-button
              >
              <el-button type="primary" @click="submitForm1('workForm')"
                >保存地址</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import location from "../../assets/images/size.png";

export default {
  data() {
    return {
      zoom: 14,
      center: [],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      windows: [],
      tipInput: "",
      loading: false,
      map: undefined,
      tips: [],
      window: "",
      workDialogVisible: false, //工作地点弹窗
      workForm: {
        street: "",
        housenumber: "",
      },
      tablist: [
        { name: "企业基本信息", id: 1, path: "/basicinfo" },
        { name: "公司相册", id: 2, path: "/photoalbum" },
        { name: "考勤设置", id: 3, path: "" },
        { name: "职位等级设置", id: 4, path: "/positionlevel" },
      ],
      activenum: 3,
      ruleForm: {
        companyId: "",
        createDate: "",
        extWorkDescription: "",
        signInDate: "",
        signOutDate: "",
        lat: "",
        lng: "",
        extWorkSalary: 3,
        signRange: 100,
        signAddress: "",
        signBuild: "",
        signAddressExt: "",
      },
      gzoptions: [
        {
          value: "09:00",
          label: "09:00",
        },
        {
          value: "10:00",
          label: "10:00",
        },
      ],
      companyId: null,
      handleForBack: {
        handle: false,
        callback: "",
      }
    };
  },
  created() {
    this.getWorkSet();
    this.getUserInfo();
  },
  activated() {
    this.routeChecker();
    this.getWorkSet();
  },
  methods: {
    routeChecker(){
      if (this.$route.query.type){
        if(this.$route.query.type === 'callback'){
          this.handleForBack.handle = true;
          this.handleForBack.callback = this.$route.query.from;
          // console.log("routeChecker, handle for back, this.handler = ", this.handleForBack);
        }
      }
    },
    getUserInfo() {
      this.$api.getUserInfo("get").then((res) => {
        this.companyId = res.data.companyUser.companyId;
      });
    },
    tipChange(value) {
      for (const tip of this.tips) {
        if (value === tip.id) {
          this.workForm.street = tip.name;
          this.ruleForm.signBuild = tip.name;
          let markers = [];
          let windows = [];
          let basePosition = [tip.location.lng, tip.location.lat];
          // let icon = new AMap.Icon({
          // 	image: location,
          // 	size: new AMap.Size(2, 2),
          // 	imageSize: new AMap.Size(2, 2)
          // });
          // content添加相应的内容**********
          markers.push({
            position: basePosition,
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: "content1111",
            offset: [0, -50],
          });
          windows.push({
            position: basePosition,
            isCustom: true,
            offset: [10, -40], // 窗体偏移
            visible: true, // 初始是否显示
            address: tip.name,
          });
          this.zoom = 15;
          this.markers = markers;
          this.windows = windows;
          this.center = [tip.location.lng, tip.location.lat];
          this.ruleForm.lng = tip.location.lng;
          this.ruleForm.lat = tip.location.lat;
          this.window = windows[0];
          break;
        }
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        AMap.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          const autoOptions = {
            city: "全国",
          };
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function (status, result) {
            // console.log(result);
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || [];
            that.loading = false;
          });
        });
      } else {
        this.tips = [];
      }
    },
    submitForm1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.signAddress =
            this.workForm.street + this.workForm.housenumber;
          this.workDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    closeworkDialogbox() {
      this.workDialogVisible = false;
    },
    getWorkSet() {
      this.$api.getWorkSet("get").then((res) => {
        for (let k in this.ruleForm) {
          if (k == "signInDate" || k == "signOutDate") {
            this.ruleForm[k] = res.data[k]
              ? new Date("2016-6-10" + " " + res.data[k])
              : "";
          } else {
            this.ruleForm[k] = res.data[k];
          }
        }
      });
    },
    changetab(path) {
      this.$router.push(path);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

          let startH = this.ruleForm.signInDate.getHours();
          let startM = this.ruleForm.signInDate.getMinutes();
          let startS = this.ruleForm.signInDate.getSeconds();
          let endH = this.ruleForm.signOutDate.getHours();
          let endM = this.ruleForm.signOutDate.getMinutes();
          let endS = this.ruleForm.signOutDate.getSeconds();
          this.ruleForm.signInDate = startH + ":" + startM + ":" + startS;
          this.ruleForm.signOutDate = endH + ":" + endM + ":" + endS;
          this.ruleForm.companyId = this.companyId;
          this.ruleForm.signRange = this.ruleForm.signRange?this.ruleForm.signRange:0;
          this.$api.saveWorkSet("post", this.ruleForm).then((res) => {
            this.getWorkSet();
            this.$message({
              message: res.code == 10200 ? "设置成功" : res.message,
              type: res.code == 10200 ? "success" : "error",
            });
            if(this.handleForBack.handle){
              let path = this.handleForBack.callback;
              this.handleForBack = {
                handle: false,
                callback: "",
              }
              this.$router.push({path: path,});
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tipInput {
  width: 300px;
}
.amap-wrapper {
  width: 500px;
  height: 250px;
  margin-bottom: 20px;
}
#info-window {
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.work-box-out {
  .work-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 60px 30px 60px;
      .demo-ruleForm {
        button {
          width: 160px;
          height: 40px;
          background-color: #00bcff;
        }
        .qux {
          background-color: #fff;
          border: 1px solid #00bcff;
          color: #00bcff;
        }
      }
    }
  }
}
.tab-list {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  div {
    margin-right: 30px;
    color: #666666;
  }
  .activeclass {
    color: #111111;
    position: relative;
    font-weight: 600;
  }
  .activeclass :after {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    bottom: -6px;
    background: #00bcff;
    left: 30%;
  }
}
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 80px 30px 160px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-ruleForm {
  width: 700px;
  /deep/ .el-input__inner,
  /deep/ .el-textarea__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
    width: 500px;
    margin-top: 50px;
    background: #00bcff;
    border: none;
  }
  /deep/ .el-input-group__append,
  /deep/ .el-input-group__prepend {
    border-radius: 0 !important;
    background-color: #dddddd;
    color: #333333;
  }
  .imgts {
    color: #999999;
  }
}
</style>
